<template>
  <div>
    <MaterialPreview
      :style="{ visibility: pageInited ? 'visible' : 'hidden' }"
      :materialTitle="courseTitle"
      :materialIntro="courseIntro"
      :materialCover="courseCover"
      :skillArr="skillArr"
      :contentArr="contentArr"
      :examList="examList"
    />

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';

  import PageLoading from '@/components/PageLoading/index.vue';
  import MaterialPreview from '@/components/MaterialPreview/MaterialPreview.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { generateTaskItem } from '@/views/material/index/task-build/generate-data.js';

  import { GetCourseDetail } from '@/api/course.js';

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);
  const route = useRoute();

  const pageInited = ref(false);
  const courseID = route.query.cid;
  const courseTitle = ref('');
  const courseIntro = ref('');
  const courseCover = ref('');
  const skillArr = ref([]);
  const contentArr = ref([]);
  const examList = ref([]);

  function initData() {
    if (!courseID) {
      return;
    }
    loading.value = true;
    GetCourseDetail({
      'Course[id]': courseID,
      'Course[type]': 2
    }).then(({ code, data }) => {
      if (code === SUCCESS && data) {
        pageInited.value = true;
        const { all_skill, base_name, exam_list, image, introduce, list } = data;
        courseTitle.value = base_name || '';
        courseIntro.value = introduce || '';
        courseCover.value = image || '';
        if (Array.isArray(exam_list) && exam_list.length > 0) {
          examList.value = exam_list;
        }
        if (Array.isArray(all_skill)) {
          skillArr.value = all_skill; // [{ id: xxx, score: xxx, title: xxx }]
        }
        if (Array.isArray(list) && list.length > 0) {
            const formattedList = [];
            list.forEach(item => {
              switch (item.has) {
                case 0:
                  formattedList.push(item);
                  break;
                case 1: // 教材
                  Array.isArray(item.chapter_list) && item.chapter_list.forEach(materialTaskItem => {
                    materialTaskItem.has = item.has;
                    formattedList.push(materialTaskItem);
                  });
                  break;
              }
            });
            contentArr.value = formattedList.map(item => generateTaskItem(false, item));
          }
      }
    }).finally(() => {
      loading.value = false;
    });
  }
  initData();
</script>